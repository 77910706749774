import { notify } from "../context/notificationService";

export const downloadReportFiles = async (url:string,fileType: string,reportId:string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = downloadUrl;
    const date = new Date().toLocaleDateString();
    a.download = fileType === 'json'
    ? `${reportId}_${date}.json`
    : `${reportId}_${date}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading file:', error);
    notify(`Error while downloadin file for ${reportId}`,"error");
  }
};

export const formatIndianCurrency = (num: number): string => {
  const parts = num.toFixed(2).split('.'); // Ensure two decimal places
  let integerPart = parts[0];
  const decimalPart = parts[1];

  // Add commas to the integer part as per Indian numbering system
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  if (otherDigits) {
    integerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThreeDigits;
  }

  return `${integerPart}.${decimalPart}`;
};

export const getUserName = (email: string) => {
  if (!email?.includes("@")) return email; // Fallback to email if invalid

  const namePart = email.split("@")[0]; // Extract part before @
  const nameParts = namePart.split(/[._]/); // Split by '.' or '_'

  const firstName = nameParts[0] ? capitalize(nameParts[0]) : "";
  const lastName = nameParts[1] ? capitalize(nameParts[1]) : "";

  return lastName ? `${firstName} ${lastName}` : firstName; // Return "First Last" or just "First"
};

// Helper function to capitalize first letter
const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);