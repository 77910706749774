import { Box, Button, Chip, CircularProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DayFilterButton from "../components/buttons/DayFilterButton";
import AnalyticsCard from "../components/cards/AnalyticsCard";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBSAReports, getStatusAnalytics } from "../api/apiCalls";
import { RootState } from "../redux/rootReducer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRecentAnalysisReportsList } from "../redux/slices/allAnalysisSlice";
import CheckReportStatusButton from "../components/buttons/CheckReportStatusButton";
import DownloadReportButton from "../components/buttons/DownloadReportButton";
import { statusKeyMap } from "../constants/common.constants";
import TableSkeleton from "../components/TableSkeleton";
import { getUserName } from "../utils/utils";
const startFirstReportImg = require('../assets/images/startFirstReport.jpg');

type StatusKey = 'total' | 'analysed' | 'inProgress' | 'onHold';

const Dashboard = () => {
  const dayFilter = useSelector((state: RootState) => state.dashboard.currentDayFilter);
  const recentReports = useSelector((state: RootState) => state.allAnalysis.recentReports);
  const username = useSelector((state: RootState) => state.settings.username);
  const [page, setPage] = useState<number>(0);
  const rowsPerPage = 5;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dayFilterItems = ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'Last 90 days'];
  const data: Array<{
    title: string; key: StatusKey; color: {
      titleColor: string;
      background: string;
      textColor: string;
    }
  }> = [
      {
        title: 'Total Applications',
        key: 'total',
        color: {
          titleColor: '#190482',
          background: '#1F299129',
          textColor: '#2D336B'
        }
      },
      {
        title: 'Completed',
        key: 'analysed',
        color: {
          titleColor: '#009D7B',
          background: '#96DED1',
          textColor: '#003F31'
        }
      },
      {
        title: 'In Progress',
        key: 'inProgress',
        color: {
          titleColor: '#B38643',
          background: '#EADDCA',
          textColor: '#664D26'
        }
      },
      {
        title: 'On Hold',
        key: 'onHold',
        color: {
          titleColor: '#D76662',
          background: '#F1D1CD',
          textColor: '#562927'
        }
      }
    ];

  const [statusData, setstatusData] = useState({
    "total": 0,
    "analysed": 0,
    "inProgress": 0,
    "onHold": 0
  });

  const [loading, setLoading] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);


  const fetchReportStatusAnalytics = async () => {
    setLoading(true);
    try {
      const endDate = new Date();
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      switch (dayFilter) {
        case 'Yesterday':
          startDate.setDate(startDate.getDate() - 1);
          break;
        case 'Last 7 days':
          startDate.setDate(startDate.getDate() - 7);
          break;
        case 'Last 30 days':
          startDate.setDate(startDate.getDate() - 30);
          break;
        case 'Last 90 days':
          startDate.setDate(startDate.getDate() - 90);
          break;
        default:
          break;
      }
      const resp = await getStatusAnalytics(startDate.toISOString(), endDate.toISOString());
      const newStatusData = { total: 0, analysed: 0, inProgress: 0, onHold: 0 };
      if (resp.data) {
        newStatusData.total = resp.data.ALL;
        newStatusData.analysed = resp.data.POSTED + resp.data.ANALYSED + resp.data.CALLBACK_FAILED;
        newStatusData.inProgress = resp.data["IN PROGRESS"];
        newStatusData.onHold = resp.data["ANALYSIS ON HOLD"] + resp.data["FAILED"]
      }
      setstatusData(newStatusData);
    } catch (error) {
      console.log('error getting status analytics:', error);
    } finally {
      setLoading(false);
    }
  }

  const fetchRecentReports = async () => {
    try {
      setLoading(true);
      setLoadingReports(true);
      const resp = await getBSAReports({ page: 1, limit: 20 });
      if (resp.data) {
        dispatch(setRecentAnalysisReportsList(resp.data.reports || []));
      }
    } catch (error) {
      console.log('error:', error);
    } finally {
      setLoadingReports(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchReportStatusAnalytics();
  }, [dayFilter]);

  useEffect(() => {
    fetchRecentReports();
  }, []);

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("en-IN", { day: "2-digit", month: "short", year: "numeric" });
    const formattedTime = date.toLocaleTimeString("en-IN", { hour: "2-digit", minute: "2-digit", hour12: true });
    return (
      <>
        <Typography fontSize={'14px'}>{formattedDate}</Typography>
        <Typography sx={{ fontSize: '14px', color: 'customColors.gray-200' }}>{formattedTime}</Typography>
      </>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>{loading && <Box sx={{ position: 'absolute', height: '100%', display: 'flex', zIndex: 998, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color="primary" size={50} />
    </Box>}
      <Box sx={{ padding: '10px 30px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ color: 'black', fontWeight: 700, fontSize: '20px' }}>
            Hi, {getUserName(username)} !
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, gap: '10px' }}>
            {dayFilterItems.map((item, index) => {
              return (<DayFilterButton key={index} text={item} />)
            })}
          </Box>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '20px', mt: 3 }}>
          {data.map((item, index) => {
            return (<AnalyticsCard key={index} title={item.title} value={statusData[item.key]} color={item.color} />)
          })}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ color: '#212B36', fontWeight: 700, fontSize: '20px' }}>
              Recent Activity
            </Typography>
            <Button variant="contained" size="small" sx={{ paddingX: '20px', fontWeight: 700, fontSize: '12px', borderRadius: '12px' }} onClick={() => navigate("/all-analysis")}>View All Reports<ArrowRightIcon /></Button>
          </Box>
          <Paper sx={{ borderRadius: '16px', mt: 2 }}>
            {(!loadingReports && recentReports.length === 0) ? <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center', height: '60vh', borderRadius: '16px', backgroundColor: 'white' }}>
              <img
                src={startFirstReportImg}
                style={{ width: "150px", minHeight: "150px" }}
                alt={""}
              />
              <Typography variant="h4" sx={{ color: '#212B36', fontWeight: 700, fontSize: '16px' }}>
                Start your first report
              </Typography>
              <Typography sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px', maxWidth: '280px', textAlign: 'center' }}>
                Submit a bank statement to generate insights. Your analyses will appear here once ready.
              </Typography>
              <Button color="primary" variant="contained" size="small" sx={{ borderRadius: '12px' }} onClick={() => navigate("/initiate-analysis")}>
                Initiate Analysis
              </Button>
            </Box> :
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ "& .MuiTableCell-head": { color: "customColors.gray-200", fontWeight: 400, fontSize: "14px", lineHeight: "24px", whiteSpace: "nowrap", textWrap: "nowrap", minWidth: "120px" } }}>
                      <TableCell>Report ID</TableCell>
                      <TableCell>Created On</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingReports ? <TableSkeleton rowCount={rowsPerPage} columnCount={5} /> : <>
                      {recentReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report) => (
                        <TableRow key={report._id}>
                          <TableCell><strong>{report.reportId}</strong></TableCell>
                          <TableCell>{formatDate(report.createdAt)}</TableCell>
                          <TableCell>
                            <Typography>
                              {getUserName(report.createdBy)}
                            </Typography>
                            <Typography sx={{ color: "customColors.gray-200" }}>
                              {report.createdBy}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={statusKeyMap[report.status] ? statusKeyMap[report.status].text : report.status}
                              sx={{
                                backgroundColor: statusKeyMap[report.status]?.background || "customColors.gray-200",
                                color: statusKeyMap[report.status]?.textColor || "customColors.gray-200",
                                textTransform: "capitalize"
                              }}
                              variant="filled"
                            />                      </TableCell>
                          <TableCell align="right">
                            {(["ANALYSIS ON HOLD", "IN PROGRESS", "ON HOLD"].includes(report.status)) ? (
                              <CheckReportStatusButton reportId={report.reportId} color={statusKeyMap[report.status]?.textColor || "customColors.gray-200"} />
                            ) : (
                              <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "12px" }}>
                                <Link to={`/all-analysis/${report.reportId}`} style={{ textDecoration: "none", color: "#1F2991", fontWeight: '700' }}>
                                  View report
                                </Link>
                                <DownloadReportButton reportId={report.reportId} />
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}</>}
                  </TableBody>
                </Table>
                <Pagination
                  count={Math.ceil(recentReports.length / rowsPerPage)}
                  page={page + 1}
                  color="primary"
                  onChange={(event, value) => setPage(value - 1)} // Adjusting for 0-based state
                  sx={{ alignItems: "center", display: "flex", justifyContent: "center", my: 1 }}
                />
              </TableContainer>}
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

export default Dashboard;