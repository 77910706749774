import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { getBSAReports } from "../api/apiCalls";
import { useEffect, useState } from "react";
import DownloadReportButton from "../components/buttons/DownloadReportButton";
import CheckReportStatusButton from "../components/buttons/CheckReportStatusButton";
import { useDispatch } from "react-redux";
import { setAllAnalysisReportsList } from "../redux/slices/allAnalysisSlice";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { Link } from "react-router-dom";
import { statusKeyMap } from "../constants/common.constants";
import ReportListTable from "../components/ReportsListTable";

// const statuses = [
//   {
//     title:'All',
//     key:'',
//   },
//   {
//     title: 'Analysed',
//     key: '',
//   },
//   {
//     title: 'In Progress',
//     key: '',
//   },
//   {
//     title: 'On Hold',
//     key: '',
//   },
// ]
// const dayFilterItems = ['Today', 'Yesterday', 'Last 7 days', 'Last 30 days', 'Last 90 days'];
interface Report {
  consumerId: string;
  createdAt: string;
  reportId: string;
  reportType: string;
  status: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

interface FilterQuery {
  page: number;
  limit: number;
  startDate?: string;
  endDate?: string;
}

type SortDirection = "asc" | "desc";

interface SortConfig {
  key: keyof Report;
  direction: SortDirection;
}
const AllAnalysis = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalReports, setTotalReports] = useState(0);

  const [loadingReports, setLoadingReports] = useState(false);
  const [filter, setFilter] = useState<FilterQuery>({
    page: 1,
    limit: 10,
  })

  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: "createdAt", direction: "desc" });

  const handleSort = (column: keyof Report) => {
    setSortConfig((prevConfig) => ({
      key: column,
      direction: prevConfig.key === column && prevConfig.direction === "asc" ? "desc" : "asc",
    }));
  };

  // const sortedReports = [...(reports || [])].sort((a, b) => {
  //   if (a[sortConfig.key] < b[sortConfig.key]) {
  //     return sortConfig.direction === "asc" ? -1 : 1;
  //   }
  //   if (a[sortConfig.key] > b[sortConfig.key]) {
  //     return sortConfig.direction === "asc" ? 1 : -1;
  //   }
  //   return 0;
  // });

  const fetchPageData = async (page?: number, limit?: number, dayFilter?: string) => {
    try {
      // setLoading(true);
      setLoadingReports(true);
      const query: FilterQuery = { ...filter };
      if (page) {
        query.page = page + 1;
      }
      if (limit) {
        query.limit = limit;
      }
      
      if (dayFilter) {
        const endDate = new Date();
        let startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        switch (dayFilter) {
          case 'Yesterday':
            startDate.setDate(startDate.getDate() - 1);
            break;
          case 'Last 7 days':
            startDate.setDate(startDate.getDate() - 7);
            break;
          case 'Last 30 days':
            startDate.setDate(startDate.getDate() - 30);
            break;
          default:
            startDate.setDate(startDate.getDate() - 90);
            break;
        };

        query.startDate = startDate.toISOString();
        query.endDate = endDate.toISOString();
      }

      const resp = await getBSAReports(query);
      if (resp.data) {
        setCurrentPage(resp.data.page ? resp.data.page - 1 : 0);
        dispatch(setAllAnalysisReportsList(resp.data.reports || []));
        setTotalReports(resp.data.totalReports ? resp.data.totalReports : 0)
      }
    } catch (error) {
      console.log('error getting bsa reports:', error);
    } finally {
      setLoadingReports(false);
      // setLoading(false);
    }
  }
  // const handleStatusFilter = (dayFilter:string)=>{
  //   fetchPageData();
  // }

  const handleChangePage = (page: number) => {
    fetchPageData(page);
  }
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newLimitQuery = { ...filter };
    newLimitQuery.limit = Number(e.target.value);
    newLimitQuery.page = 0;
    setFilter(newLimitQuery);
    fetchPageData(0, newLimitQuery.limit);
  }

  useEffect(() => {
    fetchPageData();
  }, []);
  return (
    <Box sx={{ padding: '20px 30px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" sx={{ color: 'black', fontWeight: 700, fontSize: '20px' }}>
          All Reports
        </Typography>
        {/* <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 3 , gap: '10px'}}>
              {statuses?.map(({title,key},index)=>{
                return (
                  <Button variant="contained" key={index}
                    sx={{
                      // backgroundColor: active ? 'primary' : 'rgb(245, 245, 245)',
                      // color: active ? 'white' : 'rgb(109, 110, 115)',
                      fontWeight: 700,
                      textTransform: 'none',
                      borderRadius: '8px',
                      boxShadow: 'rgba(145, 158, 171, 0.24) 0px 8px 16px 0px'
                    }}
                    size="small"
                    onClick={handleStatusFilter}
                    >
                    {title}
                  </Button>
                )
              })}
            </Box> */}
      </Box>
      <Box>
        <Paper sx={{ mt: "12px", borderRadius: "12px" }}>
          {/* <TableContainer sx={{height:"70vh", overflowY:"scroll",borderRadius:"12px",width:"100%"}}>
              <Table stickyHeader  sx={{width:"100%"}}>
                <TableHead>
                  <TableRow sx={{width:"100%"}}>
                    <TableCell sx={{backgroundColor:"customColors.gray-100"}}>
                      <TableSortLabel
                        active={sortConfig.key === "reportId"}
                        direction={sortConfig.key === "reportId" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("reportId")}
                        sx={{
                          ":hover": {
                            color: "customColors.gray-200"
                          },
                          color: "customColors.gray-200",
                          fontWeight: "600",
                          "& .MuiTableSortLabel-icon": {
                            color: "customColors.gray-200"  // Default icon color
                          },
                          "&.Mui-active": {
                            color: "black",  // Active state color for text
                            "& .MuiTableSortLabel-icon": {
                              color: "black"  // Active state color for icon
                            }
                          }
                        }}
                      >
                        Report Id
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{backgroundColor:"customColors.gray-100"}}>
                      <TableSortLabel
                        active={sortConfig.key === "createdAt"}
                        direction={sortConfig.key === "createdAt" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("createdAt")}
                        sx={{
                          ":hover": {
                            color: "customColors.gray-200"
                          },
                          color: "customColors.gray-200",
                          fontWeight: "600",
                          "& .MuiTableSortLabel-icon": {
                            color: "customColors.gray-200"
                          },
                          "&.Mui-active": {
                            color: "black",
                            "& .MuiTableSortLabel-icon": {
                              color: "black"
                            }
                          }
                        }}
                      >
                        Created At
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{backgroundColor:"customColors.gray-100"}}>
                      <TableSortLabel
                        active={sortConfig.key === "status"}
                        direction={sortConfig.key === "status" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("status")}
                        sx={{
                          ":hover": {
                            color: "customColors.gray-200"
                          },
                          color: "customColors.gray-200",
                          fontWeight: "600",
                          "& .MuiTableSortLabel-icon": {
                            color: "customColors.gray-200"
                          },
                          "&.Mui-active": {
                            color: "black",
                            "& .MuiTableSortLabel-icon": {
                              color: "black"
                            }
                          }
                        }}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ textAlign: "right", backgroundColor:"customColors.gray-100", color:"customColors.gray-200", fontWeight:"600"}}>Download Analysis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{backgroundColor:"customColors.white"}}>
                  {loadingReports ?
                  Array.from({ length: 12 }).map((_, index) => (
                    <TableRow key={index}>
                      {Array.from({length:4}).map((_,idx)=>(
                      <TableCell key={idx}>
                        <Skeleton />
                      </TableCell>
                      ))}
                    </TableRow>
                  )) : (
                    sortedReports.length > 0  ?
                    sortedReports.map((report) => (
                      <TableRow key={report.reportId}>
                        <TableCell>{report.reportId}</TableCell>
                        <TableCell>{new Date(report.createdAt).toLocaleString()}</TableCell>
                        <TableCell>{report.status}</TableCell>
                        <TableCell sx={{display:"flex",justifyContent:"flex-end"}}>{report.status === "IN PROGRESS" ? <CheckReportStatusButton reportId={report.reportId} color={statusKeyMap[report.status].textColor || "customColors.gray-200"}/> : <Box sx={{display:"flex", gap:"12px", alignItems:"center"}}>
                        <Link to={`/all-analysis/${report.reportId}`} style={{textDecoration:"none", color:"#1F2991", fontWeight:'700'}}>View report</Link><DownloadReportButton reportId={report.reportId} /></Box>}</TableCell>
                      </TableRow>
                    )) : <TableRow>
                    <TableCell colSpan={4} sx={{ border: "none" }}>
                      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" , fontSize:"24px"}}>
                        No reports found!
                      </Box>
                    </TableCell>
                  </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalReports}
              page={currentPage}
              onPageChange={(e,page) => handleChangePage(page)}
              rowsPerPage={filter.limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          <ReportListTable loading={loadingReports} page={currentPage} rowsPerPage={filter.limit} totalReports={totalReports} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
        </Paper>
      </Box>
    </Box>
  )
}

export default AllAnalysis;