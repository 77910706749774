import { Box, Typography } from "@mui/material";

interface AnalyticsCardProps {
  title: string;
  value: number;
  color: {
    titleColor: string;
    background: string;
    textColor: string;
  }
}
const AnalyticsCard: React.FC<AnalyticsCardProps> = ({title, value, color}) => {
  return (
    <Box sx={{backgroundColor: color.background, borderRadius: '20px', display:'grid', gap: '6px', padding: '20px',    boxShadow: "rgba(145, 158, 171, 0.24) 0px 1px 2px 0px"}}>
      <Typography variant="body1" sx={{color: color.titleColor, fontWeight: 600, fontSize: '14px', lineHeight: '22px'}}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{color: color.textColor, fontWeight: 700, fontSize: '24px', lineHeight: '36px'}}>
      {value ? value : 0} 
      </Typography>
    </Box>
  )
}

export default AnalyticsCard;