type StatusKeyMap = {
  [key: string]: {
    text: string,
    background: string,
    textColor: string
  }
}
const statusKeyMap: StatusKeyMap = {
  'ANALYSED': {
    text: 'Completed',
    background: '#96DED1',
    textColor: '#003F31'
  },
  'IN PROGRESS': {
    text: 'In Progress',
    background: '#EADDCA',
    textColor: '#664D26'
  },
  'ANALYSIS ON HOLD': {
    text: 'On Hold',
    background: '#F1D1CD',
    textColor: '#562927'
  }
}

export { statusKeyMap }