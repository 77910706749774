import { Box, IconButton, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { useState } from "react";
import { formatIndianCurrency } from "../../../utils/utils";

const GeneralOverview = () => {
	const reportId = useSelector((state: RootState) => state.report.reportId);
	const overviewData = useSelector((state: RootState) => state.report.overview.data.general);

	const [page, setPage] = useState(0);
	const rowsPerPage = 10;

	return <Box>
		<Paper sx={{ borderRadius: '12px' }}>
			<Box sx={{
				padding: '16px', borderRadius: '12px', background: 'linear-gradient(180deg, rgba(31, 41, 145, 0.1152) 0%, rgba(255, 255, 255, 0.24) 100%)'
			}}>
				<Typography sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>Report ID</Typography>
				<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>{reportId}</Typography>
			</Box>
			<Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Bank Score
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.bankScore}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Volatility Score
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.volatilityScore}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Circular Txns
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.circularTxnCount}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Bounced Cheques
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.bouncedChequeCount}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Transactions
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.transactionsCount}
					</Typography>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '12px' }}>
						{new Date(overviewData?.tabDetails?.startDate).toLocaleDateString('en-GB', {
							day: '2-digit',
							month: 'short',
							year: 'numeric'
						})}
						{' - '}
						{new Date(overviewData?.tabDetails?.endDate).toLocaleDateString('en-GB', {
							day: '2-digit',
							month: 'short',
							year: 'numeric'
						})}
					</Box>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Statements Uploaded
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{overviewData?.tabDetails?.statementsCount}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						First transaction
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{new Date(overviewData?.tabDetails?.startDate).toLocaleDateString('en-GB', {
							day: '2-digit',
							month: 'short',
							year: 'numeric'
						})}
					</Typography>
				</Box>

				<Box sx={{ padding: '16px', border: ' 1px solid #E5E7EB' }}>
					<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
						Last transaction
					</Box>
					<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
						{new Date(overviewData?.tabDetails?.endDate).toLocaleDateString('en-GB', {
							day: '2-digit',
							month: 'short',
							year: 'numeric'
						})}
					</Typography>
				</Box>
			</Box>
			<Box sx={{ padding: '16px' }}>
				<Box sx={{ color: 'customColors.gray-200', fontWeight: 400, fontSize: '14px' }}>
					Account statement generated for the period(s)
				</Box>
				<Typography sx={{ color: 'secondary.main', fontWeight: 700, fontSize: '18px', my: '8px' }}>
					{overviewData?.statementDetails?.map((statement) => ((statement.startDate != "" && statement.endDate != "") ? (new Date(statement.startDate).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: 'short',
						year: 'numeric'
					}) + ' - ' + new Date(statement.endDate).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: 'short',
						year: 'numeric'
					})) : "")).join(", ")}
				</Typography>
			</Box>
		</Paper>

		<Paper sx={{ overflow: "auto", borderRadius: "12px", mt: 2 }}>
			<Box display="flex" justifyContent="space-between" alignItems="center" padding={"16px"}>
				<Typography variant="h6" fontWeight="bold">
					Bank Statements Comparison
				</Typography>
				<IconButton>
					<ViewColumnIcon />
				</IconButton>
			</Box>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow sx={{ backgroundColor: "#919EAB14" }}>
							<TableCell sx={{ textWrap: 'nowrap', position: 'sticky',left: 0, zIndex: 1, backgroundColor:  "#f4f4f4"  }}>Bank Statement</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>Account Holders</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>Email</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>Phone No.</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>Avg. Bank Bal.</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>PAN</TableCell>
							<TableCell sx={{ textWrap: 'nowrap' }}>Address</TableCell>
						</TableRow>
					</TableHead>
					<TableBody sx={{
						backgroundColor: "customColors.white", '& .MuiTableRow-root:nth-of-type(even)': {
							backgroundColor: "#919EAB14"
						},
						"& .MuiTableCell-root": {
							border: 'none',
							minWidth: "100px"
						}
					}}>
						{overviewData.statementDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
							<TableRow key={index}>
								<TableCell sx={{position: 'sticky',left: 0, zIndex: 1, backgroundColor: index % 2 === 0 ? "white" : "#f4f4f4" }}>
									<Typography fontWeight="bold">{row.accountNumber}</Typography>
									<Typography fontSize="small">{row.bankName}</Typography>
									<Typography fontSize="small">{row.accountType}</Typography>
									<Typography fontSize="small" noWrap>
										{(row.startDate != "" && row.endDate != "") ? (new Date(row.startDate).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric'
										}) + ' - ' + new Date(row.endDate).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric'
										})) : ""}
									</Typography>
								</TableCell>
								<TableCell>{row.accountHolderName}</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>{row.phoneNumber}</TableCell>
								<TableCell>{Number(row.abbWithoutLoan) ? "₹"+ formatIndianCurrency(Number(row.abbWithoutLoan)) : ""}</TableCell>
								<TableCell>{row.panNumber}</TableCell>
								<TableCell>{row.customerAddress}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Pagination
				count={Math.ceil(overviewData.statementDetails.length / rowsPerPage)}
				page={page + 1}
				color="primary"
				onChange={(event, value) => setPage(value - 1)} // Adjusting for 0-based state
				sx={{ alignItems: "center", display: "flex", justifyContent: "center", my: 1 }}
			/>
		</Paper>
	</Box>
}

export default GeneralOverview;