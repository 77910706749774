import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Box, Button, Popover } from "@mui/material";
import { RootState } from "../redux/rootReducer";
import Sidebar from "../components/sidebar/Sidebar";
import { useEffect, useState } from "react";
// import { LogoutRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { clearToken } from "../redux/slices/userSlice";
import { useSnackbar } from "../context/SnackbarProvider";
import { getUserApi } from "../api/apiCalls";
import { setSettings } from "../redux/slices/settingsSlice";
import { ScrollProvider } from "../context/ScrollContext";

// const notHeaderPages = ["/all-analysis/"]

const ProtectedRoutes = () => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {showNotification} = useSnackbar();

  const [isOpen,setIsOpen] = useState(true);
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const open = Boolean(anchorEl);
  // const [showHeader,setShowHeader] = useState(true);

  const fetchUserDetails = async()=>{
    try {
      const userdetails = await getUserApi();
      dispatch(setSettings(userdetails));
    } catch (error) {
      console.log("error getting complete user details:", error);
    }
  }

  useEffect(()=>{
    if(user.token){
      fetchUserDetails();
    }
  },[user.token])

  useEffect(()=>{
    if(user.token && (user.expiryTime < Date.now())){
      showNotification("session expired please login again!","error");
      dispatch(clearToken());
    }
    // if(notHeaderPages.some((path) => pathname.startsWith(path))){
    //   setShowHeader(false);
    // }else{
    //   setShowHeader(true);
    // }
  },[pathname])

  if(!user.token){
    return <Navigate to="/login" replace />
  }
  const handleCollapseToggle = () => setIsOpen(!isOpen);
  // const handleLogout=() => {
  //   dispatch(clearToken());
  // }
  return <Box sx={{ display: "flex" }}>
    <Sidebar isOpen={isOpen} handleCollapseToggle={handleCollapseToggle}/>
    <ScrollProvider>
      <Outlet />
    </ScrollProvider>
  </Box>
}

export default ProtectedRoutes;