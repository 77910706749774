import React from 'react';
import { Container, Snackbar, Alert } from '@mui/material';
import FileDropzone from './FileDropZone';
import { FileRejection } from 'react-dropzone';
import { RootState } from '../../redux/rootReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAnalysisFilesArray, updateFileUploadStatus } from '../../redux/slices/initiateAnalysisSlice';
import { uploadFile } from '../../api/apiCalls';
import { notify } from '../../context/notificationService';

const FileInput = () => {
  const [error, setError] = React.useState<string | null>(null);
  const filesData = useSelector((state: RootState) => state.initiateAnalysis.filesArray);
  const dispatch = useDispatch();

  const handleDropAccepted = async (files: File[]) => {
    console.log('Accepted files:', files);
    const tempData = [...filesData];
    for (let i = 0; i < files.length; i++) {
      const fileData = filesData.find(file => file.fileName === files[i].name);
      if (!fileData) {
        tempData.push({ fileUrl: '', fileName: files[i].name, password: '', size: files[i].size, uploading: 'inprogress', file: files[i] });
      }
    }
    dispatch(setAnalysisFilesArray(tempData));

    for (let i = 0; i < files.length; i++) {
      const fileData = filesData.find(file => file.fileName === files[i].name);
      if (fileData && fileData.uploading === 'success') {
        continue;
      }
      try {
        const data = await uploadFile(files[i], '1 day', true);
        dispatch(updateFileUploadStatus({ fileName: files[i].name, fileUrl: data.file.directURL, status: 'success' }));
      } catch (error) {
        notify(`Upload ${files[i].name} failed`, 'error');
        dispatch(updateFileUploadStatus({ fileName: files[i].name, fileUrl: '', status: 'error' }));
      }
    }
  };

  const handleDropRejected = (rejections: FileRejection[]) => {
    const errorMessage = rejections
      .map((rejection) => `File ${rejection.file.name} was rejected`)
      .join(', ');
    setError(errorMessage);
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Container sx={{ padding: '16px !important' }}>
      <FileDropzone
        onDropAccepted={handleDropAccepted}
        onDropRejected={handleDropRejected}
      />

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FileInput;
