import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Report } from "../types";
interface AllAnalysisState {
  reports: Report[],
  recentReports: Report[],
}

const initialState: AllAnalysisState = {
  reports: [],
  recentReports:[],
}
const AllAnalysisSlice = createSlice({
  name: "allAnalysis",
  initialState,
  reducers: {
    setAllAnalysisReportsList: (state, action: PayloadAction<Report[]>) => {
      state.reports = action.payload;
    },
    setRecentAnalysisReportsList: (state, action: PayloadAction<Report[]>) => {
      state.recentReports = action.payload;
    },
    updateReportStatus: (state, action: PayloadAction<{ reportId: string; status: string }>) => {
      const { reportId, status } = action.payload;
      const reportIndex = state.reports.findIndex(report => report.reportId === reportId);
      const recentReportIndex = state.recentReports.findIndex(report => report.reportId === reportId);

      if (reportIndex !== -1) {
        state.reports[reportIndex].status = status;
      }
      if(recentReportIndex !== -1){
        state.recentReports[recentReportIndex].status = status;
      }
    },
  },
});

export const {setAllAnalysisReportsList, setRecentAnalysisReportsList, updateReportStatus } = AllAnalysisSlice.actions;
export default AllAnalysisSlice.reducer;
