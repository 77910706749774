import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/rootReducer"
import { formatIndianCurrency } from "../../utils/utils";

const DailyBalancePage = () => {
  const dailyBalanceData = useSelector(((state: RootState) => state.report.dailyBalance.data));
  return (
    <Paper sx={{ backgroundColor: "customColors.white", borderRadius: "12px", minHeight: "70vh" }}>
      <Box >
        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "24px" }}>
          <Typography fontWeight={700} lineHeight="30px" fontSize={24} variant="h5">
            Daily Balance
          </Typography>
        </Box>

        <TableContainer sx={{ borderTop: "1px solid", borderColor: 'divider' }}>
          <Table>
            <TableHead sx={{
              "& .MuiTableCell-head": {
                color: "customColors.gray-200",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                whiteSpace: "nowrap",
                backgroundColor: "#f4f4f4"
              }
            }}>
              <TableRow>
                <TableCell sx={{ width: '100px',position: 'sticky',left: 0, zIndex: 1, backgroundColor: "#f4f4f4" }}>
                  Day
                </TableCell>
                <TableCell sx={{ width: '120px', textAlign: 'right' }}>
                  Average
                </TableCell>
                {
                  dailyBalanceData.month.map((month, idx) => {
                    return <TableCell sx={{ width: '120px', textAlign: 'right' }} key={idx}>
                      {month}
                    </TableCell>
                  })
                }
              </TableRow>
            </TableHead>
            <TableBody sx={{
              '& .MuiTableRow-root:nth-of-type(even)': {
                backgroundColor: "#f4f4f4"
              },
            }}>
              {Array.from({ length: 31 }).map((_, idx) => {
                return <TableRow key={idx}>
                  <TableCell sx={{ width: '100px',position: 'sticky',left: 0, zIndex: 1, backgroundColor: idx % 2 === 0 ? "white" : "#f4f4f4" }}>
                    {idx + 1}
                  </TableCell>
                  <TableCell sx={{ width: '120px', textAlign: 'right' }}>
                  ₹{formatIndianCurrency(Number(dailyBalanceData?.day[idx + 1].at(-1)?.amount))}
                  </TableCell>
                  {
                    dailyBalanceData?.day[idx + 1]?.map((day, dayIdx) => {
                      if (dayIdx === dailyBalanceData.day[idx + 1].length - 1) {
                        return null;  // Don't render anything for the last item as it is average
                      }

                      return (
                        <TableCell sx={{ width: '120px', textAlign: 'right' }} key={dayIdx}>
                          ₹{formatIndianCurrency(Number(day.amount))}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper >
  )
}

export default DailyBalancePage