import { Box, Paper, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react";
import GeneralOverview from "./overview/GeneralOverview";

const overviewTabs = { "General": 0};
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const VerticalTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const OverviewPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ display: "flex", position:"relative" }}>
      <Tabs
        orientation="vertical"
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="side tabs"
        sx={{
          border: "none",
          top: "0",
          minWidth: "180px",
          position: "sticky",
          alignSelf: "flex-start", // Keeps it fixed within the container
          "& .MuiTabs-indicator": {
            display: "none", // Hides the active tab indicator
          }
        }}
      >
        {Object.keys(overviewTabs).map((tabName, index) => {
          const isActive = selectedTab === index;
          return (
            <Tab
              label={tabName}
              {...a11yProps(index)}
              sx={{
                color: "customColors.gray-200",
                textTransform: "none",
                fontWeight: "600",
                fontSize: "14px",
                backgroundColor: isActive ? "rgba(31, 41, 145, 0.08)" : "transparent",
                border: isActive ? "2px solid rgba(31, 41, 145, 0.24)" : "none",
                borderRadius: "8px",
              }}
              key={index}
              iconPosition="end"
            />
          );
        })}
      </Tabs>
      {/* Right side - Content */}
      <Box
        sx={{
          flexGrow: 1,
          paddingLeft:3,
          overflowY: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" }
        }}
      >
        <VerticalTabPanel value={selectedTab} index={overviewTabs["General"]}>
          <GeneralOverview />
        </VerticalTabPanel>
      </Box>
    </Box>
  );
}

export default OverviewPage;