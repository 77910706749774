import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { ChevronRight, Info } from "@mui/icons-material";
import { CustomTooltip } from "../CustomTooltip";
import { formatIndianCurrency } from "../../utils/utils";
import { useEffect, useState } from "react";
import BalanceIcon from "../../assets/icons/BalanceCheck.svg";
import PatternIcon from "../../assets/icons/TransactionPattern.svg";
import SuspiciousIcon from "../../assets/icons/Suspicious.svg";

const FraudIndicators = ()=>{
  const irregularitiesData = useSelector((state:RootState)=>state.report.irregularities.data);

  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((prev) => {
      if (isExpanded) {
        return [...prev, panel];


      } else {
        return prev.filter((val) => val !== panel);
      }
    });
  };

  const expandAll = ()=>{
   let tempPanel = [];
   for(let index=0;index<irregularitiesData.length;index++){
    let m = irregularitiesData[index].activities.length;
    const panelArray = Array.from({ length: m }, (_, colIndex) => `panel${index}${colIndex}`);
    tempPanel.push(...panelArray);
   }
   setExpanded(tempPanel);
  }

  const collapseAll = () => {
    setExpanded([]);
  };

  const  getCategoriesIcon = (value: string): React.ReactNode => {
    const icons: Record<string, React.ReactNode> = {
      accounting: <img src={BalanceIcon} alt="" />,
      transaction: <img src={PatternIcon} alt="" />,
      metadata: <img src={SuspiciousIcon} alt="" />,
    };

    for (const key in icons) {
      if (value.toLowerCase().includes(key)) {
        return icons[key];
      }
    }
    return <img src={SuspiciousIcon} alt="" />;
  }

  useEffect(()=>{
    expandAll();
  },[irregularitiesData])
  return (
    <Paper sx={{backgroundColor:"customColors.white", borderRadius:"12px",minHeight:"70vh"}}>
      <Box sx={{padding:"24px"}}>
        <Box sx={{display:"flex",justifyContent:"space-between",mb:"24px"}}>
          <Typography fontWeight={700} lineHeight="30px" fontSize={24} variant="h5" marginBottom={2}>
            Irregularities
          </Typography>
          <Button variant="outlined" sx={{color:"customColors.gray-200", fontSize:"14px", fontWeight:700, height:"36px"}} onClick={expanded.length > 0 ? collapseAll: expandAll}>
            {expanded.length > 0  ? "Collapse All" : "Expand All"}
          </Button>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column", gap:"28px"}}>
          {irregularitiesData.length > 0 ? irregularitiesData.map((irregularity,index)=> {
            const CategoryIcon = getCategoriesIcon(irregularity.category);
            return(<Box key={index} sx={{ flexGrow: 1 }}>
              <Box sx={{mb:"8px",alignItems:"center",display:"flex", gap:"8px"}}>
                {CategoryIcon}
                <Typography fontWeight={700} lineHeight="30px" fontSize={20} variant="h5">
                  {irregularity.category}
                </Typography>
              </Box>
              <Box sx={{borderRadius:"8px"}}>
                {irregularity.activities.map((activity,idx)=>{
                  return(
                    <Accordion key={idx} sx={{
                        "&.MuiAccordion-root:first-of-type":{
                          borderTopLeftRadius:"8px",
                          borderTopRightRadius:"8px",
                        },
                        "&.MuiAccordion-root:last-of-type":{
                          borderBottomLeftRadius:"8px",
                          borderBottomRightRadius:"8px",
                        },
                        "&.MuiAccordion-root":{
                        borderTop:"1px solid #919EAB50",
                        marginY:"0px"
                        },
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          transition: "transform 0.2s ease-in-out",
                        },
                        "&.Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
                          transform: "rotate(90deg)", // Rotate 90 degrees when expanded
                        },
                      }}
                      expanded={(expanded.includes(`panel${index}${idx}`) && Number(activity.incidences) > 0)}
                      onChange={handleChange(`panel${index}${idx}`)}
                      >
                      <AccordionSummary
                        expandIcon={<ChevronRight />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{
                          flexDirection: "row-reverse", // Moves the icon to the beginning
                          gap:"8px",
                          padding:"4px 12px",
                        }}
                        disabled={activity.incidences === "0"}
                        >
                          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%", alignItems:"center"}}>
                            <Box sx={{display:"flex", gap:"8px",alignItems:"center"}}>
                            <Typography fontSize="16px" fontWeight={400}>
                              {activity.activity}
                            </Typography>
                            <CustomTooltip
                              arrow
                              title={activity.description}
                              placement="top"
                              sx={{fontSize:"12px",fontWeight:400,maxWidth:"300px"}}
                              slotProps={{
                                  popper: {
                                      modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, 0],
                                            },
                                          },
                                      ],
                                  },
                              }}
                            >
                              <Info sx={{color:"customColors.gray-200",width:"18px", height:"18px"}}/>
                            </CustomTooltip>
                            </Box>
                            <Box sx={{height:"20px",width:"20px",display:"flex",alignItems:"center",
                              justifyContent:"center", fontSize:"12px", fontWeight:700, borderRadius:"50%",
                              backgroundColor: (activity.incidences === "0") ? "#919EAB33": "#D76662", color:(activity.incidences === "0") ? "customColors.gray-200":"customColors.white"
                              }}>
                              {activity.incidences}
                            </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{backgroundColor:"customColors.gray-50", minHeight:"68px", borderTop:"1px solid #919EAB50"}}>
                        <Box>
                          {activity.transactions?.length > 0 && <TableContainer sx={{maxHeight:"30vh", overflow:"scroll", borderColor: 'divider',backgroundColor:"customColors.gray-50"}}>
                            <Table stickyHeader>
                              <TableHead sx={{backgroundColor:"customColors.white", position:"sticky",top:"0","& .MuiTableCell-head":{
                                color:"customColors.gray-200",
                                fontWeight:600,
                                fontSize:"14px",
                                lineHeight:"24px",
                                whiteSpace:"nowrap",
                              }}}>
                                <TableRow>
                                  <TableCell>
                                    Date
                                  </TableCell>
                                  <TableCell>
                                    Particulars
                                  </TableCell>
                                  <TableCell>
                                    Cheque/Ref. No.
                                  </TableCell>
                                  <TableCell>
                                    Counterparty
                                  </TableCell>
                                  <TableCell>
                                    Amount (₹)
                                  </TableCell>
                                  <TableCell>
                                    Balance (₹)
                                  </TableCell>
                                  <TableCell>
                                    Computed balance (₹)
                                  </TableCell>
                                  <TableCell>
                                    Tags
                                  </TableCell>
                                  <TableCell>
                                    Category
                                  </TableCell>
                                  <TableCell>
                                    UPI app
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody sx={{"& .MuiTableCell-root":{
                                borderBottom:"none"
                                }}}>
                                {activity.transactions.map((transaction,tIndex) => (
                                  <TableRow key={tIndex}>
                                    <TableCell sx={{textWrap:"nowrap"}}>
                                      {transaction.date ? new Date(transaction.date).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: 'short',
                                        year: 'numeric'
                                      }) : '-'}
                                    </TableCell>
                                    <TableCell>
                                      {transaction.particular}
                                    </TableCell>
                                    <TableCell>
                                      {transaction.cheque_number}
                                    </TableCell>
                                    <TableCell>
                                      {transaction.counterparty}
                                    </TableCell>
                                    <TableCell sx={{color: transaction.type === "CR" ? "#009D7B":"#D76662",textWrap:"nowrap",textAlign:"right"}}>
                                      {transaction.type === "CR" ? "+ ₹" : "- ₹"}
                                      {formatIndianCurrency(transaction.amount)}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                    ₹{formatIndianCurrency(transaction.balance)}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                    ₹{formatIndianCurrency(transaction.computedBalance)}
                                    </TableCell>
                                    <TableCell>
                                      <Box sx={{display:"flex",flexWrap:"wrap", gap:"8px"}}>
                                      {transaction.tags?.split(",").map((tag,index)=>(
                                        <Box sx={{borderRadius:"8px", padding:"4px", backgroundColor:"customColors.gray-50",fontSize:"13px",fontWeight:500,whiteSpace:"nowrap"}} key={index}>
                                          {tag}
                                        </Box>
                                      ))}
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box sx={{display:"flex",flexWrap:"wrap", gap:"8px",fontSize:"13px",fontWeight:500}}>
                                        {transaction.categories.split(" / ").map((category,index)=>(
                                          <Box sx={{borderRadius:"8px", padding:"4px", backgroundColor:"customColors.purple-200",color:"customColors.blue-700",whiteSpace:"nowrap"}} key={index}>
                                          {category}
                                          </Box>
                                        ))}
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      {transaction.upiApp}
                                    </TableCell>
                                  </TableRow>
                                  ))}
                            </TableBody>
                            </Table>
                            </TableContainer>}
                         {activity.monthlyTransactions?.length > 0 && <TableContainer sx={{maxHeight:"30vh", overflow:"scroll",backgroundColor:"customColors.gray-50"}}>
                          <Table stickyHeader sx={{width:"70%", position:"relative"}}>
                            <TableHead sx={{backgroundColor:"customColors.white", position:"sticky",top:"0","& .MuiTableCell-head":{
                              color:"customColors.gray-200",
                              fontWeight:600,
                              fontSize:"14px",
                              lineHeight:"24px",
                              whiteSpace:"nowrap",
                            }}}>
                              <TableRow>
                                <TableCell>
                                  Month
                                </TableCell>
                                <TableCell>
                                  Counterparty
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Transactions
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Debit Amount
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Debit %
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Credit Amount
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Credit %
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{ maxHeight:"30vh", overflow:"scroll","& .MuiTableCell-root":{
                              borderBottom:"none"
                            }}}>
                              {
                                activity.monthlyTransactions.map((transaction,mIndex) => (
                                  <TableRow key={mIndex}>
                                    <TableCell sx={{textWrap:"nowrap"}}>
                                     {transaction.month}
                                    </TableCell>
                                    <TableCell>
                                      {transaction.counterparty}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                      {transaction.txnCount}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                    ₹{formatIndianCurrency(Number(transaction.debitAmount))}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                      {transaction.pctDebit ? `${transaction.pctDebit}%` : "-"}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                      ₹{formatIndianCurrency(Number(transaction.creditAmount))}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                      {transaction.pctCredit ? `${transaction.pctCredit}%` : "-"}
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>}

                        {activity.files?.length > 0 && <TableContainer sx={{ maxHeight:"30vh", overflow:"scroll",backgroundColor:"customColors.gray-50"}}>
                          <Table stickyHeader>
                            <TableHead sx={{backgroundColor:"customColors.white", position:"sticky",top:"0","& .MuiTableCell-head":{
                              color:"customColors.gray-200",
                              fontWeight:600,
                              fontSize:"14px",
                              lineHeight:"24px",
                              whiteSpace:"nowrap",
                            }}}>
                              <TableRow>
                                <TableCell>
                                  File Id
                                </TableCell>
                                <TableCell>
                                  File Name
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Fraud Score
                                </TableCell>
                                <TableCell>
                                  Authenticity
                                </TableCell>
                                <TableCell>
                                  Bank Name
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                  Account Number
                                </TableCell>
                                <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                   Account Type
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody sx={{"& .MuiTableCell-root":{
                              borderBottom:"none"
                            }}}>
                              {
                                activity.files.map((file,fIndex) => (
                                  <TableRow key={fIndex}>
                                    <TableCell sx={{textWrap:"nowrap"}}>
                                     {file.fileId}
                                    </TableCell>
                                    <TableCell>
                                      {file.fileName}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                      {file.fraudScore}
                                    </TableCell>
                                    <TableCell>
                                      {file.authenticity}
                                    </TableCell>
                                    <TableCell>
                                      {file.accountDetails.bankName}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                    {file.accountDetails.accountNumber}
                                    </TableCell>
                                    <TableCell sx={{textWrap:"nowrap",textAlign:"right"}}>
                                    {file.accountDetails.accountType}
                                    </TableCell>
                                  </TableRow>
                                ))
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>}
                        </Box>
                      </AccordionDetails>
                      {/* <AccordionActions sx={{mt:"-12px",px:"16px"}}>
                        <Button variant="contained" onClick={handleAuthUpdate}>Update</Button>
                      </AccordionActions> */}
                    </Accordion>
                  )
                })}
              </Box>
            </Box>
            )}
          ) : <Box sx={{display:"flex",alignItems:"center", height:"45vh", justifyContent:"center"}}><Typography textAlign="center" fontSize={18}>No irregularities to show!</Typography></Box>}
        </Box>
      </Box>
    </Paper>
  )
}

export default FraudIndicators;