import { Box, Button, CircularProgress } from "@mui/material";
import { downLoadBSAReport } from "../../api/apiCalls";
import { useState } from "react";
import { downloadReportFiles } from "../../utils/utils"

interface DownloadReportButtonProps {
  reportId: string;
}
const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({reportId}) => {
  const [loading,setLoading] = useState(false);

  const handleDownloadReport = async() => {
    setLoading(true);
    try {
      const resp = await downLoadBSAReport({reportId,fileType:'json and excel'});

      if(resp?.result?.json){
        await downloadReportFiles(resp.result.json,'json',reportId);
      }
      if(resp?.result?.excel){
        await downloadReportFiles(resp.result.excel,'excel',reportId);
      }
    } catch (error) {
      console.log("error downloading report",error);
    }finally{
      setLoading(false);
    }
  }
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
      {loading ? <CircularProgress sx={{mr:'40px'}} size={20} /> :
      <Button sx={{borderRadius: '8px', color: '#1F2991', fontWeight: '700'}} onClick={handleDownloadReport}>
        Download report
      </Button>
      }
    </Box>
  )
}

export default DownloadReportButton;