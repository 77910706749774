import { Box, Button, CircularProgress, Typography } from "@mui/material";
import FileInput from "../components/dropbox/FileInput";
import PDFViewerModal from "../components/modal/PDFViewer";
import CustomModal from "../components/modal/Modal";
import EditAnalysisFilePassword from "../components/EditAnalysisFilePassword";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { useDispatch } from "react-redux";
import { clearAnalysisFilesArray, closePreview, setAnalysisFilesArray } from "../redux/slices/initiateAnalysisSlice";
import { analyzeStatements } from "../api/apiCalls";
import { useSnackbar } from "../context/SnackbarProvider";
import { useState } from "react";

const InitiateAnalysis = () => {
  const filesData = useSelector((state: RootState) => state.initiateAnalysis.filesArray);
  const showModal = useSelector((state: RootState) => state.initiateAnalysis.showModal);
  const previewUrl = useSelector((state: RootState) => state.initiateAnalysis.previewUrl);
  const [analysisRequested, setAnalysisRequested] = useState(false);
  const dispatch = useDispatch();
  const { showNotification } = useSnackbar();

  const handleAnalysis = async () => {
    console.log('analysis files:', filesData);
    const inProgressFile = filesData.find(file => file.uploading === 'inprogress');
    const failedUploads = filesData.find(file => file.uploading === 'error');
    if (inProgressFile) {
      return showNotification('Please wait for all files to be uploaded', 'warning');
    }
    if (failedUploads) {
      return showNotification('Please reupload or remove the failed files', 'warning');
    }
    const files = filesData.map(file => {
      return { fileUrl: file.fileUrl, password: file.password }
    })
    setAnalysisRequested(true);
    try {
      const response = await analyzeStatements(files);
      console.log('Analysis initiated successfully:', response);
      showNotification(`Analysis Initiated for ${response?.report?.reportId}`, 'info');
      dispatch(setAnalysisFilesArray([]))
    } catch (error) {
      console.log('error initiating analysis:', error);
      showNotification(`Analysis Initiation Failed`, 'error');
    }
    setAnalysisRequested(false);
  }



  return (
    <Box sx={{ padding: '20px 30px' }}>
      <CustomModal open={showModal} onClose={() => dispatch(closePreview())}><PDFViewerModal isModal={true} sourceUrl={previewUrl} />
      </CustomModal>
      <Typography variant="h4" sx={{ color: 'black', fontWeight: 700, fontSize: '20px' }}>
        Initiate Analysis
      </Typography>
      <Box sx={{ border: '1px solid rgba(145, 158, 171, 0.2)', padding: '0px', borderRadius: '12px', my: '20px', backgroundColor: 'white' }}>
        {analysisRequested ? <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: 'rgb(237, 237, 245)', margin: '12px', padding: '20px', borderRadius: '8px', minHeight: '30vh', border: '1px solid rgba(145, 158, 171, 0.2)' }}>
          <CircularProgress />
          <Typography variant="body1" fontSize={16} fontWeight={700} color="black">
            Initiating analysis</Typography>
        </Box> : <FileInput />}
        <Box sx={{ padding: '0px 16px' }}>
          {filesData.length > 0 && !analysisRequested && (<>
            <Box sx={{ display: 'grid', gap: '10px', maxHeight: '32vh', overflow: 'auto', scrollBehavior: 'smooth' }}>
              {filesData.map(({ fileUrl, fileName, password, size, uploading, file }, index) => {
                return (
                  <EditAnalysisFilePassword key={index} file={fileUrl} fileName={fileName} password={password} size={size} uploading={uploading} fileData={file} />
                )
              })}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', my: '16px' }}>
              <Box>
                <Button variant="text" sx={{ borderRadius: '8px', mr: '10px', fontWeight: '700' }} onClick={() => dispatch(clearAnalysisFilesArray())}>Cancel</Button>
                <Button variant="contained" sx={{ borderRadius: '8px' }} onClick={() => handleAnalysis()} >Start Analysis</Button>
              </Box>
            </Box></>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InitiateAnalysis;