import { Box, Button, LinearProgress, Typography } from "@mui/material";
import PasswordField from "./PasswordField";
import { CancelSharp, Delete, RefreshOutlined } from "@mui/icons-material";
import { useState } from "react";
import { removeAnalysisFile, setAnalysisFilePassWord, showPreview, updateFileUploadStatus } from "../redux/slices/initiateAnalysisSlice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../context/SnackbarProvider";
import pdfIcon from '../assets/icons/PdfIcon.svg';
import { uploadFile } from "../api/apiCalls";

interface EditAnalysisFilePasswordProps {
  file: string;
  fileName: string;
  password: string;
  size: number;
  uploading: 'inprogress' | 'success' | 'error';
  fileData: File
}

const EditAnalysisFilePassword: React.FC<EditAnalysisFilePasswordProps> = ({ file, fileName, password, size, uploading, fileData }) => {
  // const [disabled,setDisabled] = useState(true);
  const [newPassword, setNewPassword] = useState(password);
  const dispatch = useDispatch();
  const { showNotification } = useSnackbar();

  const handleRemoveFile = (name: string) => {
    dispatch(removeAnalysisFile(name));
  }
  const handlePreview = (fileUrl: string) => {
    dispatch(showPreview({ previewUrl: fileUrl }));
  }

  const handleSave = () => {
    if (newPassword !== password) {
      dispatch(setAnalysisFilePassWord({ fileName, password: newPassword }));
      showNotification(`password updated for file: ${fileName}`, 'info');
    }
    // setDisabled(true);
  }

  const handleReUpload = async() => {
    dispatch(updateFileUploadStatus({ fileName: fileName, fileUrl: '', status: 'inprogress' }));
    try {
      const data = await uploadFile(fileData, '1 day', true);
      dispatch(updateFileUploadStatus({ fileName: fileName, fileUrl: data.file.directURL, status: 'success' }));
    } catch (error) {
      showNotification(`Reupload ${fileName} failed`, 'error');
      dispatch(updateFileUploadStatus({ fileName: fileName, fileUrl: '', status:'error' }));
    }
  }

  return (<Box key={fileName} sx={{ border: '1px solid rgba(145, 158, 171, 0.2)', borderRadius: '8px', p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', verticalAlign: 'center', gap: '10px' }}>
    <Box sx={{ display: "flex", alignItems: 'center', gap: '10px' }}>
      <img src={pdfIcon} alt="pdf icon" />
      <Box>
        <Box sx={{ color: '#212B36', fontSize: '14px', fontWeight: 600, cursor: 'pointer' }} onClick={() => handlePreview(file)}>{fileName}</Box>
        {uploading === 'error' ? <Typography color="error" sx={{ fontSize: '12px' }}>Upload failed</Typography> : <Typography sx={{ color: '#637381', fontSize: '12px' }}>{Math.round(size / 1024)} KB</Typography>}
      </Box>
    </Box>
    {uploading === 'success' && <PasswordField sx={{ borderRadius: '12px' }}
      name="password" onChange={(e) => setNewPassword(e.target.value)}
      // disabled={disabled}
      labelName="Enter password if file is password protected"
      value={newPassword}
    />}
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '4px', alignItems: 'center' }}>
      {/* <Button sx={{borderRadius: '8px'}} onClick={() => setDisabled(false)}>Edit</Button> */}
      {uploading === 'inprogress' && <LinearProgress sx={{width: '300px', borderRadius: '8px', mr:'10px'}}/>}
      {uploading === 'success' && <Button sx={{ borderRadius: '12px' }} size="small" variant="contained" onClick={() => handleSave()}>Save</Button>}
      {uploading === 'inprogress' ? <CancelSharp htmlColor="#637381" fontSize="small" onClick={() => handleRemoveFile(fileName)} /> : <Delete sx={{ color: '#637381', cursor: 'pointer' }} onClick={() => handleRemoveFile(fileName)} /> }
      {uploading === 'error' && <RefreshOutlined sx={{ color: '#637381' }} onClick={() => handleReUpload()} />}
    </Box>
  </Box>)

}

export default EditAnalysisFilePassword;