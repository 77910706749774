import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AllTransactionsData, DailyBalanceReport, IrregularityData, OverviewReport, TransactionsTotalSummary } from "../types";

interface reportPageState {
  reportId:string;
  transactionsReady:boolean;
  transactions:{
    summary: TransactionsTotalSummary,
    data: AllTransactionsData,
    activeType:number;
    filterOpened: boolean;
    counterparties: string[];
    categories: string[];
    tags: string[];
    appliedFilters:{
      counterparty:string[];
      categories: string[];
      tags: string[];
      count:number;
      limit:number;
      type:"DB" | "CR" | "";
      sort: 1 | -1;
    };
    filters:{
      counterparty:string[];
      categories: string[];
      tags: string[];
      limit:number;
      type:"DB" | "CR" | "";
      sort: 1 | -1;
    }
  };
  irregularities: {
    data:IrregularityData[];
    noOfFraudIndicators:number;
  };
  dailyBalance: DailyBalanceReport;
  overview: OverviewReport;
}

const initialState: reportPageState = {
  reportId:"",
  transactionsReady:false,
  transactions:{
    summary:{
      totalExpense:0,
      totalIncome:0,
      currentBalance:0,
    },
    data: {
      page:1,
      totalPages:1,
      totalTransactions:0,
      transactions:[]
    },
    activeType:0,
    filterOpened: false,
    counterparties: [],
    categories: [],
    tags:[],
    appliedFilters:{
      counterparty: [],
      categories:[],
      tags:[],
      count:0,
      limit:200,
      type:"",
      sort:-1,
    },
    filters:{
      counterparty: [],
      categories:[],
      tags:[],
      limit:200,
      type:"",
      sort:-1,
    }
  },
  irregularities:{
    data:[],
    noOfFraudIndicators:0,
  },
  dailyBalance: {
    reportId:"",
    data: {
      month: [],
      day: {}
    }
  },
  overview: {
    reportId: "",
    data: {
      general: {
        tabDetails: {
          bankScore: 0,
          volatilityScore: 0,
          circularTxnCount: 0,
          bouncedChequeCount: 0,
          transactionsCount: 0,
          statementsCount: 0,
          startDate: "",
          endDate: "",
          period: ""

        },
        statementDetails: []
      }
    }
  }
}

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setInitialReportState:(state)=>{
      state.reportId= initialState.reportId;
      state.transactions= initialState.transactions;
      state.transactionsReady=initialState.transactionsReady;
      state.irregularities = initialState.irregularities;
      state.dailyBalance = initialState.dailyBalance;
      state.overview = initialState.overview;
    },
    setReportId:(state,action:PayloadAction<string>)=>{
      state.reportId = action.payload;
    },
    setTransactionsReady:(state,action:PayloadAction<boolean>)=>{
      state.transactionsReady = action.payload;
    },
    setTransactionsTotalSummary:(state,action:PayloadAction<TransactionsTotalSummary>)=>{
      state.transactions.summary = action.payload;
    },
    setTransactionsFilterList:(state,action:PayloadAction<{counterparties:string[],categories:string[],tags:string[]}>)=>{
      const {categories,counterparties,tags} = action.payload;
      state.transactions.categories=categories;
      state.transactions.counterparties=counterparties;
      state.transactions.tags = tags;
    },
    openTransactionsFilter:(state,action:PayloadAction<boolean>)=>{
      state.transactions.filterOpened = action.payload;
    },
    setTransactionsFilter:(state,action:PayloadAction<{counterparty?:string[],categories?:string[],tags?:string[],limit?:number,type?:"DB" | "CR" | "",sort?: 1 |-1}>)=>{
      const {counterparty,categories,tags,limit,type,sort}= action.payload;

      if(counterparty){
        state.transactions.filters.counterparty = counterparty;
      }
      if(categories){
        state.transactions.filters.categories = categories;
      }
      if(tags){
        state.transactions.filters.tags = tags;
      }
      if(limit){
        state.transactions.filters.limit = limit;
      }
      if(type !== undefined){
        state.transactions.filters.type = type;
        state.transactions.appliedFilters.type = type;
      }
      if(sort !== undefined){
        state.transactions.filters.sort = sort;
        state.transactions.appliedFilters.sort = sort;
      }
    },
    removeFromTransactionsfilter:(state,action:PayloadAction<{counterparty?:string,category?:string,tag?:string}>)=>{
      const {counterparty,category,tag}= action.payload;
      if(counterparty){
        state.transactions.filters.counterparty.filter((value)=> value !== counterparty);
      }
      if(category){
        state.transactions.filters.categories.filter((value)=> value !== category);

      }
      if(tag){
        state.transactions.filters.tags.filter((value)=> value !== tag);
      }
    },
    clearTransactionsFilter:(state)=>{
      state.transactions.filters = initialState.transactions.filters;
      state.transactions.appliedFilters = initialState.transactions.appliedFilters;
    },
    setTransactionsActiveType:(state,action:PayloadAction<number>)=>{
      state.transactions.activeType = action.payload;
    },
    setTransactionsData:(state,action:PayloadAction<AllTransactionsData>)=>{
      const {transactions} = action.payload;
      if(transactions){
        state.transactions.data = action.payload;
      }else{
        state.transactions.data = initialState.transactions.data;
      }
    },
    setAppliedTransactionsFilter:(state,action:PayloadAction<{counterparty?:string[],categories?:string[],tags?:string[],limit?:number}>)=>{
      const {counterparty,categories,tags,limit} = action.payload;
      if(counterparty){
        state.transactions.appliedFilters.counterparty = counterparty;
      }
      if(categories){
        state.transactions.appliedFilters.categories = categories;
      }
      if(tags){
        state.transactions.appliedFilters.tags = tags;
      }
      if(limit){
        state.transactions.appliedFilters.limit = limit;
      }
      let count = 0;
      count += state.transactions.appliedFilters.categories.length > 0 ? 1 : 0;
      count += state.transactions.appliedFilters.counterparty.length > 0 ? 1 : 0;
      count += state.transactions.appliedFilters.tags.length > 0 ? 1 : 0;

      state.transactions.appliedFilters.count = count;
    },
    // Irregularities reducers.
    setIrregularitiesData:(state,action:PayloadAction<IrregularityData[]>)=>{
      state.irregularities.data = action.payload;
      state.irregularities.noOfFraudIndicators = action.payload.length;
    },
    // Daily Balance
    setDailyBalanceReport:(state,action:PayloadAction<DailyBalanceReport>)=>{
      state.dailyBalance = action.payload;
    },
    setOverViewReport:(state, action:PayloadAction<OverviewReport>)=>{
      state.overview = action.payload;
    }
  },
});

export const {
  setReportId,
  openTransactionsFilter,
  setTransactionsFilterList,
  setTransactionsFilter,
  clearTransactionsFilter,
  setTransactionsData,
  removeFromTransactionsfilter,
  setAppliedTransactionsFilter,
  setTransactionsTotalSummary,
  setTransactionsActiveType,
  setTransactionsReady,
  setInitialReportState,
  setIrregularitiesData,
  setDailyBalanceReport,
  setOverViewReport,
} = reportSlice.actions;
export default reportSlice.reducer;
