import { createContext, useContext, RefObject, useRef } from "react";

type ScrollContextType = {
  scrollRef: RefObject<HTMLDivElement> | null;
};

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider = ({ children }: { children: React.ReactNode }) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  return (
    <ScrollContext.Provider value={{ scrollRef }}>
      <div ref={scrollRef} style={{ width: "100%", maxHeight: "100vh", overflowY: "auto", backgroundColor: "rgb(237, 237, 245)" }}>
        {children}
      </div>
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context.scrollRef;
};
