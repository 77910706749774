import React from "react";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, Chip, TablePagination } from "@mui/material";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import TableSkeleton from "./TableSkeleton";
import { statusKeyMap } from "../constants/common.constants";
import { getUserName } from "../utils/utils";
import CheckReportStatusButton from "./buttons/CheckReportStatusButton";
import DownloadReportButton from "./buttons/DownloadReportButton";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import searchNotFoundImg from '../assets/images/searchNotFound.jpg';
const formatDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-IN", { day: "2-digit", month: "short", year: "numeric" });
  const formattedTime = date.toLocaleTimeString("en-IN", { hour: "2-digit", minute: "2-digit", hour12: true });
  return (
    <>
      <Typography fontSize={'14px'}>{formattedDate}</Typography>
      <Typography sx={{ fontSize: '14px', color: 'customColors.gray-200' }}>{formattedTime}</Typography>
    </>
  );
};
interface ReportListTableProps {
  loading: boolean;
  totalReports: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReportListTable: React.FC<ReportListTableProps> = ({
  loading,
  totalReports,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const reports = useSelector((state: RootState) => state.allAnalysis.reports);

  return (
    <>
      <TableContainer sx={{ maxHeight: "80vh", overflowY: "scroll", borderRadius: "12px", width: "100%" }}>
        <Table stickyHeader sx={{ width: "100%" }}>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-head": {
                  color: "customColors.gray-200",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  whiteSpace: "nowrap",
                  textWrap: "nowrap",
                  minWidth: "120px",
                  backgroundColor: 'white'
                },
              }}
            >
              <TableCell>Report ID</TableCell>
              <TableCell>Created On</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton rowCount={rowsPerPage} columnCount={5} />
            ) : (
              reports.length === 0 ? (<TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60vh",
                      borderRadius: "16px",
                      backgroundColor: "white",
                    }}
                  >
                    <img
                      src={searchNotFoundImg}
                      style={{ width: "150px", minHeight: "150px" }}
                      alt=""
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#212B36",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                    >
                      No reports found!
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              ) :
                (reports.map((report) => (
                  <TableRow key={report._id}>
                    <TableCell>
                      <strong>{report.reportId}</strong>
                    </TableCell>
                    <TableCell>{formatDate(report.createdAt)}</TableCell>
                    <TableCell>
                      <Typography>{getUserName(report.createdBy)}</Typography>
                      <Typography sx={{ color: "customColors.gray-200" }}>{report.createdBy}</Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={statusKeyMap[report.status]?.text || report.status}
                        sx={{
                          backgroundColor: statusKeyMap[report.status]?.background || "customColors.gray-200",
                          color: statusKeyMap[report.status]?.textColor || "customColors.gray-200",
                          textTransform: "capitalize",
                        }}
                        variant="filled"
                      />
                    </TableCell>
                    <TableCell align="right">
                      {["ANALYSIS ON HOLD", "IN PROGRESS", "ON HOLD"].includes(report.status) ? (
                        <CheckReportStatusButton reportId={report.reportId} color={statusKeyMap[report.status]?.textColor || "customColors.gray-200"} />
                      ) : (
                        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "12px" }}>
                          <Link to={`/all-analysis/${report.reportId}`} style={{ textDecoration: "none", color: "#1F2991", fontWeight: "700" }}>
                            View report
                          </Link>
                          <DownloadReportButton reportId={report.reportId} />
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>)
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalReports}
        page={page}
        onPageChange={(event, newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /></>
  );
};


export default ReportListTable;
