import { TableRow, TableCell, Skeleton } from "@mui/material";

interface TableSkeletonProps {
  rowCount: number;
  columnCount: number;
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ rowCount, columnCount }) => {
  return (
    <>
      {Array.from({ length: rowCount }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: columnCount }).map((_, columnIndex) => (
            <TableCell key={columnIndex}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
